@import '../../styles/propertySets.css';

.popupSize {
  padding: 0 10px 7px 10px;
}

.plainSize{
  height: 400px;
  background-color: var(--dropdownColorYellow);
  display: flex;
  flex-direction: column;
  justify-content:space-between;
}

.duration {
  padding: 0 24px 24px 24px;
}

.customCalendar {
  background-color: var(--dropdownColorYellow);
}

.customClear {
  font-weight: var(--fontWeightMedium);
  margin: 0 auto 0 0;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  color: var(--matterColorLight);
}

.customCancel {
  font-weight: var(--fontWeightMedium);
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  margin-left: 48px;
  color: var(--matterColorLight);
}

.customApply {
  color: var(--highlightColorBlue);
  font-weight: var(--fontWeightMedium);
  margin: 0 0 0 19px;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
}
