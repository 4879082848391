.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.field {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-right: 90px;
    margin-bottom: 32px;
  }
}

.label {
  margin-bottom: 20px;
}

.serviceItem {
  padding-right: 90px;

  /* Size */
  width: 100%;

  /* Layout */
  display: flex;
  align-items: flex-end;

  /*  */
  position: relative;
}

.select {
  flex-grow: 1;
}

.addMoreService,
.removeService {
  position: absolute;
  top: 1.5em;
  right: 40px;

  /* Fill */
  background-color: var(--highlightColorYellow);

  /* Size */
  min-height: 32px;
  height: 32px;
  width: 30px;
}

.removeService {
  right: 0px;
}

.error {
  color: var(--failColor);
}

.wrapperButton {
  display: flex;
  justify-content: space-between;
}

.buttonActive {
  background-color: var(--highlightColorYellow);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
  width: 45%;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
