@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative;
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
}

.heartIcon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.fillHeart {
  color: #c97801;
}

.enableIcon {
  composes: heartIcon;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    transform: scale(1.2);
  }
}

.disableIcon {
  composes: heartIcon;
  cursor: wait;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
}

.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 16px 0 2px 0;
  position: relative;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: no-wrap;
  }
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.reviews {
  color: var(--matterColor);
  padding-top: 8px;
  @media (--viewportMedium) {
    position: absolute;
    right: 0;
    top: 4px;
  }
}

.star {
  fill: var(--marketplaceColorDark);
  transition: var(--transitionStyleButton);
  width: 18px;
  margin-left: 8px;
}

.reviewsStar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;
  @media (--viewportMedium) {
    margin-right: 0px;
    justify-content: flex-end;
  }
}
.reviewWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (--viewportMedium) {
    align-items: unset;
    flex-direction: column;
    justify-content: flex-end;
  }
}
.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.certificateInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
