@import '../../styles/customMediaQueries.css';

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  width: 100%;

  @media (--viewportMedium) {
    display: flex;
  }
}

.hasAnimation {
  composes: animation;
  animation-delay: 0.9s;
}

.markIcon {
  margin-right: 10px;
  object-fit: contain;
  width: 15px;

  @media (--viewportMedium) {
    width: 20px;
    margin-right: 15px;
  }
}

.inputFields {
  background: var(--pearlBushTransparent);
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  @media (--viewportMedium) {
    display: flex;
    border-top-left-radius: 5px;
    margin-bottom: 0;
    border-bottom-left-radius: 5px;
    padding: 15px;
  }
}

.field {
  width: 100%;
  background: var(--pearlBush);
  display: flex;
  align-items: center;
  padding: 3px 10px;
  border-radius: 5px;
  &:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  @media (--viewportMedium) {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

.select {
  width: 100%;
  padding: 0px 10px;
  border-radius: 5px;

  @media (--viewportMedium) {
    padding: 5px 10px;
  }
}

.select select {
  border-bottom: none;
}

.submitButton {
  min-height: 50px;
  @media (--viewportMedium) {
    width: 150px;
  }
}
