@import '../../styles/customMediaQueries.css';
@import '../../styles/marketplaceDefaults.css';

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: var(--ZIndexTopbar);
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    position: relative;
    padding-top: var(--topbarHeightDesktop);
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.healing {
  @apply --marketplaceH1FontStyles;

  margin: 24px 0 24px 24px;
  font-weight: var(--fontWeightSemiBold);

  @media (--viewportMedium) {
    margin: 32px 0 48px 48px;
  }

  @media (--viewportLarge) {
    margin: 72px 0 72px 72px;
  }
}

.FavoriteListingsPanel {
  flex-grow: 1;
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;

  @media (--viewportLarge) {
    padding-left: 36px;
  }
}

.noResults {
  @apply --marketplaceH4FontStyles;
  margin-left: 24px;
  font-weight: var(--fontWeightSemiBold);

  @media (--viewportMedium) {
    margin-left: 48px;
  }

  @media (--viewportLarge) {
    margin-left: 72px;
  }

  /* Reserves 48px of vertical space */
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 12px;

  /* parent uses flexbox: this defines minimum width for translated text "noResults" */
  flex-basis: 100%;
  flex-shrink: 0;
}

.loadingResults {
  @apply --marketplaceH4FontStyles;
  line-height: 20px;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 24px 0 24px;
  margin: 0;
  background-color: var(--matterColorBright);

  @media (--viewportLarge) {
    padding: 9px 36px 0 36px;
  }
}
