@import '../../styles/propertySets.css';

.contentWrapper {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
}

.title {
  color: var(--matterColorLight);
}

.inputsWrapper {
  display: flex;
  justify-content: space-between;
  width: 120px;
}

.timeRange {
  width: 42px;
  font-size: 17px;
}

.sliderWrapper {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 13px;
}

.customRangebar {
}
