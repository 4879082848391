@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  @apply --backgroundImage;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (--viewportLarge) {
    border: 35px solid var(--matterColorLight);
  }

  /* Text positioning is done with paddings */
}

.heroContent {
  margin: 0 24px 0 24px;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);

  composes: animation;
  animation-delay: 0.5s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
  &.longTitle {
    max-width: 100%;
    @media (--viewportMedium) {
      font-size: 48px;
    }
    @media (--viewportLarge) {
      font-size: 65px;
    }
  }
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;

  composes: animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}

.listButtons {
  display: flex;
  justify-content: space-between;
}

.heroButtonDesktop {
  display: none;

  @media (--viewportMedium) {
    @apply --marketplaceButtonStylesSecondary;
    composes: animation;
    display: flex;
    justify-content: center;
    align-items: center;
    animation-delay: 0.8s;
    width: 130px;
    height: 100px;
    padding: 0 16px;
  }

  @media (--viewportLarge) {
    width: 142px;
    height: 103px;
  }

  @media (--viewportLargeWithPaddings) {
    width: 160px;
    height: 103px;
  }

  @media (--viewportExtraLarge) {
    width: 206px;
    height: 103px;
  }
}

.heroButton {
  @apply --marketplaceButtonStylesSecondary;
  composes: animation;

  animation-delay: 0.8s;

  @media (--viewportMedium) {
    display: none;
  }
}
