.root {
  display: flex;
  position: relative;
}

.emailField {
  margin-right: 5px;
  width: 100%;
}

.emailField input {
  padding: 10px 0;
}

.submitButton {
  background-color: transparent;
  position: absolute;
  right: 5px;
  width: 30px;
  top: -10px;
  height: 30px;
  &:disabled {
    background-color: transparent;
    &.arrow {
      stroke: var(--failColorLight);
    }
  }

  &:hover {
    background-color: transparent;
    box-shadow: none;
  }
}

.spinner {
  stroke: var(--successColorDark);
  width: 28px;
  height: 28px;
  stroke-width: 3px;
}

.checkmark {
  stroke: var(--successColorDark);
  width: 28px;
  height: 28px;
  stroke-width: 3px;
}

.submitError {
  margin: 0;
  font-size: 14px;
  color: var(--failColor);
}

.successMessage {
  text-align: center;
}

.thanksMessage {
  font-weight: var(--fontWeightSemiBold);
}
