@import '../../styles/propertySets.css';

.filterButton {
  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
    color: var(--highlightColorYellow);
  }
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
    color: var(--highlightColorYellow);
    font-weight: 600;

    & span {
      padding: 0px 4px 2px 4px;
      border-bottom: solid 2px var(--highlightColorYellow);
    }
  }
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--highlightColorBlue);

  background-color: var(--highlightColorBlue);

  /* &:hover,
  &:focus {
    border: 1px solid var(--highlightColorBlue);
    background-color: var(--highlightColorBlue);
  } */
}

.labelEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
