.checked {
  display: none;
  fill: var(--highlightColorYellow);
}

.box {
  stroke: var(--matterColorAnti);
}

/* Display the "check" when checked */
.isChecked .checked {
  display: inline;
  stroke: var(--highlightColorYellow);
  stroke-width: 1px;
}

.isChecked .box {
  stroke: var(--highlightColorYellow);
}
