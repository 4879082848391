.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.field {
  flex-basis: 30%;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.hiddenField {
  width: 0px;
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
}

.wrapperButton {
  display: flex;
  justify-content: space-between;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  background-color: var(--highlightColorYellow);
  width: 45%;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.currencyField{
  margin-bottom: 50px;
  @media (--viewportMedium) {
  width: 30%;

  }
}