@import '../../styles/propertySets.css';

:root {
}

.modalContent {
  padding: 60px 0 48px;
}

.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 200px;
}

.welcomeText {
  font-size: 32px;
  font-weight: var(--fontWeightBold);
}

.chooseLocation {
  font-size: 28px;
  font-weight: var(--fontWeightMedium);
  margin-bottom: 64px;
}

.secondBtn {
  margin-top: 16px;
}
