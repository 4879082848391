.sectionMap {
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.map {
  width: 320px;
  height: 320px;
  border: none;
  @media (--viewportMedium) {
    width: 340px;
    height: 340px;
  }
}

.confirmModal {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.containerModal {
  background-color: var(--matterColorLight);
  width: 80%;
  border-radius: 4px;
  padding: 20px;
  @media (--viewportMedium) {
    width: 30%;
  }
}
.confirmActions {
  display: flex;
  gap: 10px;
}

.openIn {
  margin-bottom: 30px;
  font-weight: var(--fontWeightSemiBold);
}
